import classes from "./Header.module.scss";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import jmLogo from "../assets/web/jm-logo.png";

const Header = (props) => {
  let pIsActive = false;
  let hIsActive = false;
  let aIsActive = false;

  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  if (pathname === "/about") {
    aIsActive = true;
  }

  if (pathname === "/") {
    hIsActive = true;
  }

  if (pathname.includes("portfolio")) {
    pIsActive = true;
  }

  return (
    <div className={classes.header}>
      <div
        className={classes.logo}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        <img src={jmLogo} alt="James Madeiros" />
      </div>
      <div
        className={classes.brand}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        <h1>
          James <span>Madeiros</span>
        </h1>
        <p>B2B\B2C Copy, Content + Concept Design </p>
      </div>
      <div className={classes.nav}>
        {pIsActive && (
          <Link className={classes.active} to="/">
            <i className="fal fa-arrow-left"></i>
          </Link>
        )}
        <Link to="/" className={hIsActive ? classes.active : null}>
          <i className="fal fa-home-alt"></i>
        </Link>
        <Link to="/about" className={aIsActive ? classes.active : null}>
          <i className="fal fa-user"></i>
        </Link>
      </div>
    </div>
  );
};

export default Header;
