import Content from "../../components/Content";
import classes from "./About.module.scss";
import JamesPhoto from "../../assets/web/james-about.jpg";
import Page from "../../components/Page";

const About = (props) => {
  return (
    <Content>
      <Page>
        <div className={classes.about}>
          <div className={classes["about-info"]}>
            <div className={classes["about-info-text"]}>
              <img src={JamesPhoto} alt="About Page" />

              <p>
                I grew up on a farm in rural Ohio, and ended up in the Pacific
                Northwest the same way a lot of people do - chasing true love
                and dreams of meeting Bigfoot. What can I say? I'm a sucker for
                impossible challenges.
              </p>

              <p>
                Hello and welcome, friends. I’m James - the grenade in your
                competitor's Cheerios.
              </p>

              <p>
                People often seek me out when they want to “disrupt.” When they
                want edge-case creativity. When they want ideas, words, and
                stories ChatGPT will never write, because AI is a Tin Man with
                no heart.
              </p>

              <p>
                Some say good storytelling is rooted in experience, and if
                that's true I'm in luck. A few highlights include my time as an
                Air Force public affairs officer, surviving law school,
                wrestling bears, winning brand battles in the high-stakes sports
                drink market, and getting my ass mercilessly kicked in online
                luxury furniture.
              </p>

              <p>
                Success inspires confidence, while failure invites humility -
                and I feel both are critical to award-winning creative
                collaboration. Well, that and the robots. No use fighting the
                future.
              </p>

              <p>
                So! What's next? Maybe you can tell me. I'd love to discuss
                where you want to go and how we can work together to get there.
              </p>

              <p className={classes["contact-info"]}>
                Reach me any time at
                <br />{" "}
                <a href="mailto: james.madeiros@gmail.com">
                  james.madeiros@gmail.com
                </a>{" "}
                <br /> or <a href="tel:2068619233">206-861-9233</a>.
              </p>
            </div>
          </div>
        </div>
      </Page>
    </Content>
  );
};

export default About;
