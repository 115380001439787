import { Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import WebsiteContainer from "./components/WebsiteContainer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import PortfolioPage from "./pages/portfolio/Portfolio";

export default function Layout({ children }) {
  return (
    <WebsiteContainer>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio/:brand" element={<PortfolioPage />} />
      </Routes>
      <Footer />
    </WebsiteContainer>
  );
}
