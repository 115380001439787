import classes from "./PortfolioItem.module.scss";
import YouTube from "@u-wave/react-youtube";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

function PortfolioItem({ item }) {
  if (item.type === "document") {
    return (
      <div className={classes["portfolio-display"]}>
        <Link key={item.alt} to={item.donwloadURL} target="_blank">
          <img src={item.img} alt={item.alt} />
          {item.text && <p className={classes.imageText}>{item.text}</p>}
        </Link>
      </div>
    );
  }

  if (item.type === "image") {
    return (
      <div
        key={item.alt}
        className={`
      ${classes["portfolio-display"]} 
      ${item.extraClass ? classes[item.extraClass] : ""}`}
      >
        <img
          src={item.img}
          style={{ width: "100%" }}
          alt={item.alt}
          className={item.wauto ? classes.wauto : ""}
        />
        {item.text && <p className={classes.imageText}>{item.text}</p>}
      </div>
    );
  }

  if (item.type === "video") {
    return (
      <div className={classes["portfolio-display"]}>
        <div className={classes["video-container"]}>
          <YouTube video={item.videoId} className={classes.video} />
        </div>
      </div>
    );
  }

  if (item.type === "title") {
    return (
      <table key={item.title} className={classes.descTable}>
        <tbody>
          {item.desc && (
            <tr>
              <td className={classes.descTitle} colSpan="2">
                {parse(item.desc)}
              </td>
            </tr>
          )}
          <tr>
            <td>Roles:</td>
            <td>{parse(item.roles)}</td>
          </tr>
          <tr>
            <td>Goals:</td>
            <td>{parse(item.goals)}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default PortfolioItem;
