import React, { useEffect } from "react";
import Content from "../../../components/Content";
import classes from "./PortfolioWrapper.module.scss";
import Page from "../../../components/Page";

const PortfolioWrapper = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Content>
      <Page>
        <div className={classes.portfolio}>{props.children}</div>
      </Page>
    </Content>
  );
};

export default PortfolioWrapper;
