import React from "react";
import classes from "./PortfolioFooter.module.scss";
import { Link } from "react-router-dom";

const PortfolioFooter = (props) => {
  return (
    <div className={classes["portfolio-footer"]}>
      <div className={classes["portfolio-footer-back"]}>
        <Link to={props.brandBackUrl}>
          <span className={classes["portfolio-footer-nav-icon"]}>
            <i className="fal fa-arrow-left"></i>
          </span>
          <div
            className={`${props.brandBackClass} ${classes["portfolio-footer-nav-img"]}`}
          >
            <img src={props.brandBackImg} alt={props.brandBackName} />
          </div>
        </Link>
      </div>

      <div className={classes["portfolio-footer-next"]}>
        <Link to={props.brandNextUrl}>
          <div
            className={`${props.brandNextClass} ${classes["portfolio-footer-nav-img"]}`}
          >
            <img src={props.brandNextImg} alt={props.brandNextName} />
          </div>
          <span className={classes["portfolio-footer-nav-icon"]}>
            <i className="fal fa-arrow-right"></i>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default PortfolioFooter;
