import classes from "./Footer.module.scss";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className={classes.footer}>
      <div className={classes.copy}>
        &copy;2001 - {year} James Madeiros. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
