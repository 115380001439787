import { Link } from "react-router-dom";
import classes from "./Home.module.scss";

import awsLogo from "../../assets/web/aws-logo.png";
import o2logo from "../../assets/web/o2-logo.png";
import msLogo from "../../assets/web/microsoft-logo.png";
import paccarLogo from "../../assets/web/paccar-logo.png";
import reiLogo from "../../assets/web/rei-logo.png";
import sogLogo from "../../assets/web/sog-logo.png";
import lowesLogo from "../../assets/web/lowes-logo.png";
import tbLogo from "../../assets/web/tommy-bahama-logo.png";

import Content from "../../components/Content";
import Page from "../../components/Page";

const Home = (props) => {
  return (
    <Content>
      <Page>
        <div className={classes.home}>
          <div className={classes.tagline}>
            Agency, in-house and remote experience at every team level with
            brands you know: <br /> <span>Microsoft</span>, <span>Amazon</span>,{" "}
            <span>PACCAR</span>, <span>Tommy Bahama</span>, <span>Lowe’s</span>,{" "}
            <span>REI</span>, <span>O2 Hydration</span> and more.
          </div>

          <div className={classes.work}>
            <div className={classes.brandIcon}>
              <Link to="/portfolio/o2hydration" className={classes.o2h}>
                <img src={o2logo} alt="o2 Hydration" />
              </Link>
              <span>2020-Present</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/amazon" className={classes.aws}>
                <img src={awsLogo} alt="Amazon Work" />
              </Link>
              <span>2019-2020</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/microsoft" className={classes.ms}>
                <img src={msLogo} alt="Microsoft Work" />
              </Link>
              <span>2019-2020</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/paccar" className={classes.paccar}>
                <img src={paccarLogo} alt="Paccar Work" />
              </Link>
              <span>2019-2020</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/rei" className={classes.rei}>
                <img src={reiLogo} alt="Rei Work" />
              </Link>
              <span>2018-2019</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/tommybahamas" className={classes.tb}>
                <img src={tbLogo} alt="Tomy Bahama Work" />
              </Link>
              <span>2018-2019</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/sog" className={classes.sog}>
                <img src={sogLogo} alt="Sog Work" />
              </Link>
              <span>2017-2018</span>
            </div>

            <div className={classes.brandIcon}>
              <Link to="/portfolio/lowes" className={classes.lowes}>
                <img src={lowesLogo} alt="Lowes Work" />
              </Link>
              <span>2012-2017</span>
            </div>
          </div>
        </div>
      </Page>
    </Content>
  );
};

export default Home;
