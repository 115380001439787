import Portfolio from "./Components/PortfolioWrapper";
import PortfolioFooter from "./Components/PortfolioFooter";
import classes from "./Portfolio.module.scss";

import { useBrand } from "../../hooks/useBrand";
import PortfolioItem from "./Components/PortfolioItem";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const PortfolioPage = (props) => {
  const params = useParams();
  const brand = useBrand(params.brand);

  return (
    <Portfolio>
      <div className={classes["portfolio-header"]}>
        <div className={`${classes["portfolio-logo"]} ${brand.logoColor}`}>
          <img src={brand.logo} alt={brand.name} />
        </div>

        <div
          className={`${classes["portfolio-desc"]} ${
            brand.hideNavMobile ? "hideMobile" : ""
          }`}
        >
          {brand.description.map((desc) => (
            <table key={desc.title} className={classes.descTable}>
              <tbody>
                {desc.desc && (
                  <tr>
                    <td className={classes.descTitle} colSpan="2">
                      {parse(desc.desc)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Roles:</td>
                  <td>{parse(desc.roles)}</td>
                </tr>
                <tr>
                  <td>Goals:</td>
                  <td>{parse(desc.goals)}</td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>

      <div className={classes["portfolio-files"]}>
        {brand?.items?.length > 0 &&
          brand.items.map((item) => (
            <PortfolioItem key={item.alt} item={item} />
          ))}

        <PortfolioFooter
          brandBackUrl={brand.brandBackUrl}
          brandBackImg={brand.brandBackImg}
          brandBackClass={brand.brandBackClass}
          brandBackName={brand.brandBackName}
          brandNextUrl={brand.brandNextUrl}
          brandNextImg={brand.brandNextImg}
          brandNextClass={brand.brandNextClass}
          brandNextName={brand.brandNextName}
        />
      </div>
    </Portfolio>
  );
};

export default PortfolioPage;
