import classes from "./WebsiteContainer.module.scss";

const WebsiteContainer = (props) => {
  return (
    <>
      <div className={classes.background}></div>
      <div className={classes["web-container"]}>
        <div className={classes["main-content"]}>{props.children}</div>
      </div>
    </>
  );
};

export default WebsiteContainer;
